@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);
html,
body {
  margin: 0;
  height: 100vh;
  background-color: white;
  font-family: 'Roboto', 'Segoe UI', 'Lucida Grande', 'Ubuntu', sans-serif;
}

.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before {
  opacity: 0 !important;
}

